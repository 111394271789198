
// Component to render the entire schema tree
import _, { update } from 'lodash';
import EditableLabel from '../../../components/common/editors/EditableLabel';
import { Icon } from '../../../components/common/Icon';
import { findMatchingPaths } from './SpecSchemaEditor';
import { useState } from 'react';

const typeStyles = {
  'unit': {backgroundColor: 'rgba(149,205,214,0.2)'},
  'number': {backgroundColor: '#f6e4bd'},
  'enum': {backgroundColor: 'rgba(33,161,33,0.13)', color: 'green'},
  string: {backgroundColor: '#c9bdf6'},
  boolean: {backgroundColor: '#999', color: 'white'},
  array: {backgroundColor: '#bfddfa', color: 'gray'},
  'objectTypedKeys': {backgroundColor: 'yellow'},
}


export default function SchemaTree({ 
  schema, 
  updateSchema, 
  handleDragStart, 
  handleDrop, 
  handleDragOver, 
  handleDragEnd, 
  dragState, 
  collapsedNodes, 
  toggleExpand, 
  searchText, 
  hideTitle = false, 
  hideAddRootProperty = false, 
  readonly = false,
  changes = null
  }) {
  let filteredPaths = searchText && findMatchingPaths(schema, searchText);

  return <div className="schema-tree monospace small">
    { !hideTitle ? <div className="mb-2 font-weight-bold text-center">{schema.name || 'Schema'}</div> : null }

    {schema.properties && <div className="properties-container ml-4">
      {Object.entries(schema.properties).map(([key, value]) => <SchemaNode
        key={key}
        nodeName={key}
        nodeValue={value}
        path="properties"
        updateSchema={updateSchema}
        handleDragStart={handleDragStart}
        handleDrop={handleDrop}
        handleDragOver={handleDragOver}
        handleDragEnd={handleDragEnd}
        dragState={dragState}
        collapsedNodes={collapsedNodes}
        toggleExpand={toggleExpand}
        filteredPaths={filteredPaths}
        changes={changes}
        readonly={readonly}
        level={0}
      />)}

      { !hideAddRootProperty ? <button
        className="btn btn-sm btn-outline-primary mt-2"
        onClick={() => {
          updateSchema.add(`properties.newProperty`, { type: 'boolean' });              
        }}
      >
        + Add Root Property
      </button> : null }
    </div>}
  </div>;
};


// Component to represent a single node in the schema
const SchemaNode = ({
                      nodeName, nodeValue, path, updateSchema, handleDragStart, handleDrop, dragState, handleDragEnd, handleDragOver,
                      collapsedNodes, toggleExpand, level, filteredPaths, readonly, changes
                    }) => {
  const fullPath = `${path}.${nodeName}`;
  let isExpanded = collapsedNodes[fullPath] !== true || nodeValue.type === 'enum';  // Default to expanded
  if(!nodeValue) {
    debugger;
  }
  const isObject = nodeValue.type === 'object';

  const hasChildren = nodeValue && ((isObject && nodeValue.properties)
    || (nodeValue.type === 'objectTypedKeys' && nodeValue.keys && nodeValue.valuesType)
    || (nodeValue.type === 'array' && nodeValue.items)
    || (nodeValue.type === 'union' && nodeValue.types && nodeValue.valueType));

  const [isEditing, setIsEditing] = useState(false);
  // const [editValue, setEditValue] = useState(nodeName);
  const [showTypeMenu, setShowTypeMenu] = useState(false);

  if (filteredPaths) {
    if (!_.some(filteredPaths, p => p.startsWith(fullPath) || fullPath.startsWith(p))) {
      // return null;
      // isExpanded = false;
    } else {
      // isExpanded = true;
    }
  }

  // Save edited key
  const handleKeySave = (editValue) => {
    console.log("SAVING", editValue, nodeName)
    if (editValue !== nodeName) {

      if (editValue.trim() !== '') {
        // Update the schema
        let newPath = path + '.' + editValue;

        updateSchema.move(fullPath, newPath);

        // Refocus on the type selector of the new property
        if (nodeName.startsWith('newProperty')) {
          let newNodeID = `.specs-schema-editor #${newPath.replaceAll('.', '_')} > > > .dropdown-toggle`;
          // @ts-ignore
          setTimeout(() => $(newNodeID).focus(), 10);
          console.log('FOCUS', newNodeID);
        }
      } else {
        // Delete the key
        updateSchema.remove(fullPath);
      }
    }
    setIsEditing(false);
  };

  // Handle key press events in the edit field
  // const handleKeyDown = e => {
  //   if (e.key === 'Enter') {
  //     handleKeySave();
  //   }
  // };

  // Change the type of a node
  const changeNodeType = newType => {
    // Basic type change
    let newValue = { ...nodeValue, type: newType };

    // Additional adjustments based on type
    if (newType === 'object' && !newValue.properties) {
      newValue.properties = {};
      if(nodeValue.type === 'array' && nodeValue.items.type === 'object') {
        newValue.properties = nodeValue.items?.properties;
        delete newValue.items;
      }
    } else if (newType === 'array' && !newValue.items) {
      newValue.items = { type: 'string' };
      if(nodeValue.type === 'object') {
        newValue = {type: newType, items: {... nodeValue}};
      }
    } else if (newType === 'objectTypedKeys' && !newValue.valuesType) {
      newValue = {type: newType, keys: ['key1'], valuesType: {... nodeValue}};
    } else if (newType === 'enum' && !newValue.values) {
      newValue.values = ['value1'];
    } else if (newType === 'unit' && !newValue.unit) {
      newValue.unit = 'unit';
    }

    updateSchema.update(fullPath, newValue);
    setShowTypeMenu(false);
  };

  // Add a new property to an object
  const addNewProperty = () => {
    if (isObject) {
      const properties = nodeValue.properties || {};
      let newPropName = 'newProperty';

      // Ensure unique property name
      let counter = 1;
      while (properties[newPropName]) {
        newPropName = `newProperty${counter}`;
        counter++;
      }

      updateSchema.add(`${fullPath}.properties.${newPropName}`, { type: 'boolean' });
    }
  };

  // Wrap a node in an object
  const wrapInObject = () => {
    const wrappedValue = {
      type: 'object', properties: {
        value: _.cloneDeep(nodeValue)
      }
    };

    updateSchema.update(fullPath, wrappedValue);
  };

  const updateComment = (newComment) => {
    if (!newComment)
      delete nodeValue._comments;
    else
      nodeValue._comments = newComment;

    updateSchema.update(fullPath, nodeValue);
  };

  const handleNodeKeyDown = (e) => {
    // To avoid trapping the event inside a child editable label
    const sameTarget = e.target === e.currentTarget;
    if (e.key === 'd' && e.ctrlKey) {
      updateSchema.add(`${path}.${nodeName}_copy`, _.cloneDeep(nodeValue));

      e.preventDefault();
      e.stopPropagation();
    } else if ((e.key === 'ArrowRight' || e.key === 'ArrowLeft') && sameTarget) {
      toggleExpand(fullPath);

      e.preventDefault();
      e.stopPropagation();
    }
  }

  // Render different components based on the node type
  const renderNodeContent = () => {
    // Special handling for object properties

    const recursiveProps = {
      handleDragStart,
      handleDrop,
      handleDragOver,
      handleDragEnd,
      collapsedNodes,
      dragState,
      toggleExpand,
      readonly,
      updateSchema,
      filteredPaths,
      level: level + 1,
      changes
    }

    if (isObject && nodeValue.properties) {
      const propertyEntries = Object.entries(nodeValue.properties);

      let dragPredictionShown = false;
      let draggedPropName = dragState && dragState.dragging.split('.').slice(-1)[0];
      const predictedLocationMark = dragState && <div key={draggedPropName+'_drag_prediction'} style={{
        borderTop: 'dotted 2px green', marginTop: '-1px', marginBottom: '-1px', width: '50%', marginLeft: '20px'
      }}/>;

      return <div className="ml-1 pl-2 border-left border-light-secondary">
        {_.flatten(propertyEntries.map(([propName, propValue], i) => {
          let node = <SchemaNode key={propName} nodeName={propName} nodeValue={propValue} path={`${fullPath}.properties`}{...recursiveProps}/>;

          // When dragging, show a border to indicate the predicted position according to sorted nodes
          if (!dragPredictionShown && dragState?.draggingOver === fullPath) {
            if (draggedPropName.toLowerCase() < propName.toLowerCase()) {
              dragPredictionShown = true;
              return [predictedLocationMark, node];
            } else if (i === propertyEntries.length - 1) {
              return [node, predictedLocationMark];
            }
          }
          return [node];
        }))}

        {propertyEntries.length === 0 ? <div className="text-muted fst-italic ml-2" onClick={addNewProperty}>
          {"<click to add properties>"}
        </div> : null}

        <button className="btn btn-sm btn-secondary py-0 zoom-75 parent-hover-transparent"
                style={{ position: 'relative', top: -20, left: -24, marginBottom: -30 }}
                onClick={addNewProperty}>+
        </button>
      </div>;
    }

    // Handling for array items
    if (nodeValue.type === 'objectTypedKeys') {
      return <div className="ml-0">
        <div>
          <div className="ml-1">
            {nodeValue.keys.map((val, i) => {
              return <EditableLabel
                key={i}
                className="bg-success zoom-90 text-white mr-1 px-2"
                value={val}
                onChange={txt => {
                  if (txt.trim()) {
                    updateSchema.update(`${fullPath}.keys.${i}`, txt.trim());
                  } else {
                    updateSchema.update(`${fullPath}.keys`, _.without(nodeValue.keys, val));
                  }
                }}
              />;
            })}

            <EditableLabel
              key={'extra'}
              className="border-success border-bottom zoom-90 text-success mr-1 px-2"
              style={{ minWidth: '5px' }}
              placeholder={'+'}
              value={''}
              onChange={txt => {
                if (txt.trim()) {
                  updateSchema.addItem(`${fullPath}.keys`, txt.trim());
                }
              }}
            />
          </div>
        </div>
        <SchemaNode
          nodeName="valuesType"
          nodeValue={nodeValue.valuesType}
          path={fullPath}
          {...recursiveProps}
        />
      </div>;
    }

    // Handling for array items
    if (nodeValue.type === 'array' && nodeValue.items) {
      return <div className="ml-0">
        <SchemaNode
          nodeName="items"
          nodeValue={nodeValue.items}
          path={fullPath}
          {...recursiveProps}
        />
      </div>;
    }

    // Union types
    if (nodeValue.type === 'union' && nodeValue.types) {
      return <div className="ml-4">
        <div className="fw-bold mb-1">Union Types:</div>
        {nodeValue.types.map((type, index) => <SchemaNode
          key={index}
          nodeName={`type${index + 1}`}
          nodeValue={type}
          path={`${fullPath}.types`}
          {...recursiveProps}
        />)}
        <button
          className="btn btn-sm btn-outline-primary mt-2"
          onClick={() => {
            const newTypes = [...nodeValue.types, { type: 'string' }];
            updateSchema.update(`${fullPath}.types`, newTypes);
          }}
        >
          + Add Type
        </button>
      </div>;
    }

    // Enum values
    if (nodeValue.type === 'enum' && nodeValue.values) {
      return <div className="ml-1">
        {nodeValue.values.map((val, i) => {
          return <EditableLabel
            key={i}
            className="bg-success zoom-90 text-white mr-1 px-2"
            value={val}
            onChange={txt => {
              if (txt.trim()) {
                updateSchema.update(`${fullPath}.values`, nodeValue.values.toSpliced(i, 1, txt.trim()));
              } else {
                updateSchema.update(`${fullPath}.values`, _.without(nodeValue.values, val));
              }
            }}
          />;
        })}

        <EditableLabel
          key={'extra'}
          className="border-success border-bottom zoom-90 text-success mr-1 px-2"
          style={{ minWidth: '5px' }}
          placeholder={'+'}
          value={''}
          onChange={txt => {
            if (txt.trim()) {
              updateSchema.addItem(`${fullPath}.values`, txt.trim());
            }
          }}
        />
      </div>;
    }

    // Unit type
    if (nodeValue.type === 'unit') {
      return <span className="ml-1">
          <EditableLabel
            className="text-secondary px-1 align-middle bg-light-info font-weight-bold border border-info"
            style={{fontSize: '12px'}}
            value={nodeValue.unit} placeholder={'...'} onChange={txt => updateSchema.update(`${fullPath}.unit`, txt)}
          />
      </span>;
    }

    // For simple types with no children
    return null;
  };

  let beignDragged = dragState?.dragging === fullPath;
  let dragClass = beignDragged ? 'bg-light-danger rounded' : '';
  let dragOverClass = dragState?.draggingOver === fullPath ? 'bg-light-success rounded' : '';

  const editComment = (!readonly || nodeValue._comments) ? <span
    className={`ml-4 mr-3 text-secondary font-italic no-wrap flex-grow-1 text-right zoom-90 ${nodeValue._comments ? '' : 'parent-hover-visible'}`}>
        //<EditableLabel className={'align-top text-wrap'} value={nodeValue._comments} onChange={updateComment} placeholder={'Comment...'}/>
      </span> : null;

  const showInSameLine = !(isObject || nodeValue.type === 'objectTypedKeys' || nodeValue.type === 'array');

  const changeAdded = _.some(changes, ({op, path, fromPath, toPath}) => (path) === fullPath);
  const changeRenamed = _.some(changes, ({toPath}) => (toPath) === fullPath);
  const changeClass = changeAdded ? 'schema-node--added' : changeRenamed ? 'schema-node--renamed' : '';

  return <div
    className={`schema-node pl-2 ${dragClass} ${changeClass} ${dragOverClass} mb-${isObject ? 0 : 0} ${level > 0 ? 'ml-2' : ''}`}
    style={{ borderColor: '#dee2e6' }}
    onDragEnter={e => handleDragOver(e, fullPath)}
    onDragOver={e => handleDragOver(e, fullPath)}
    onDrop={e => handleDrop(e, fullPath)}
    data-path={fullPath}
  >
    <div
      draggable={!isEditing}
      onDragStart={e => handleDragStart(e, fullPath, nodeValue)}
      onDragEnd={handleDragEnd}
      className={`d-flex align-items-baseline pb-0 spec-schema-node`}
      id={fullPath.replaceAll('.', '_')}
      onKeyDown={e => handleNodeKeyDown(e)}
      tabIndex={0}
      style={hasChildren ? { marginLeft: '-14px' } : {}}
    >
      <Icon icon={'drag_indicator'} style={{ color: beignDragged ? 'green' : '#BBB' }} className={'drag-icon parent-hover-transparent'}/>

      {hasChildren &&
        <button className="btn btn-sm mr-1 p-0 text-secondary" onClick={(e) => toggleExpand(fullPath, e.shiftKey)}>
          {isExpanded ? '▼' : '►'}
        </button>}


      <span className={'mr-2 text-nowrap'}>
        <EditableLabel value={nodeName}
                       autoFocus={!readonly && (nodeName.startsWith('newProperty') || nodeName.endsWith('_copy'))}
                       className={'node-name ' + (filteredPaths?.includes(fullPath) ? 'bg-highlight' : '')}
                       onChange={(textContent) => handleKeySave(textContent)}/>:
      </span>

      <div className="d-flex align-items-center">
        <div className="dropdown">
          {(nodeValue.type !== 'object' || _.isEmpty(nodeValue.properties))
            ?
            <button className="btn btn-sm zoom-90 btn-outline-secondary dropdown-toggle py-0"
                    onBlur={() => setShowTypeMenu(false)}
                    tabIndex={0}
                    style={typeStyles[nodeValue.type] || {}} type="button"
                    onClick={() => setShowTypeMenu(!showTypeMenu)}>
              {nodeValue.type}
            </button> : null}

          {showTypeMenu &&
            <div className="position-absolute bg-white border rounded p-2 shadow" style={{ zIndex: 1000 }}>
              {
                ['object', 'boolean', 'string', 'number', 'unit', 'enum', 'array', 'objectTypedKeys'].map((type, k) =>
                  <div key={type} className="dropdown-item" style={{ ...(typeStyles[type] || {}), cursor: 'pointer' }}
                       onMouseDown={(e) => e.preventDefault()} // Prevents on blur                       
                       tabIndex={k}
                       onKeyDown={(e) => e.key === 'Enter' && changeNodeType(type)}
                       onClick={() => changeNodeType(type)}>
                    {type}
                  </div>)
              }
              <div className="dropdown-divider"></div>
              {/*<div className="dropdown-item" onClick={convertToUnionType} style={{ cursor: 'pointer' }}>*/}
              {/*  Convert to Union Type*/}
              {/*</div>*/}
              {/*<div className="dropdown-item" onClick={wrapInObject} style={{ cursor: 'pointer' }}>*/}
              {/*  Wrap in Object*/}
              {/*</div>*/}
            </div>}
        </div>
      </div>

      {isObject && isExpanded ? editComment : null}

      {isExpanded && showInSameLine && renderNodeContent()}

      {!isExpanded && isObject ?
        <span className={'bg-light rounded px-1'} onClick={() => toggleExpand(fullPath)}>{"{ ... }"}</span> : null}

      {!isObject || !isExpanded ? editComment : null}
    </div>


    {isExpanded && !showInSameLine && renderNodeContent()}

  </div>;
};
